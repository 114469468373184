/**
 * The root reducer function used by the Redux store to update state.
 * All reducers should be registered in this file to have an effect.
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import { defaulti18nState } from "./i18n-middleware"; // OVERWRITE
// Importing application-specific reducers
import servWork from "./servWork/reducers";
import notifications from "./notifications/reducers";
import xapis from "./xapis";
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import faqs from "./faqs/reducers";
import faqcategories from "./faqcategories/reducers";
import fonts from "./fonts";
import cmsblocks from "./cmsblocks";
import landingpages from "./landingpages";
import editorSize from "./editor/size";
import editorShop from "./editor/shop";
import editorFile from "./editor/file";
import shopProducts from "./shop/products";
import shopTaxons from "./shop/taxons";
import shopCart from "./shop/cart";
import shopCheckout from "./shop/checkout";
import shopAdyen from "./shop/pay-adyen";
import shopAmazonPay from "./shop/pay-amazon";
import shopPayPalPay from "./shop/pay-paypal";
import shopCountries from "./shop/countries";
import shopErrors from "./shop/errors";
import formSteps from "./supportcenter/form_steps";
import formStepsOptions from "./supportcenter/form_step_options";
import cases from "./supportcenter/cases";
import blogPosts from "./blogposts";
import orderReviews from "./cop/order_reviews";
import lineItemProblems from "./cop/line_item_problem";
import lineItemFeedbacks from "./cop/line_item_feedback";
import lineItemProblemSolutions from "./cop/line_item_problem_solution";
import tmpFiles from "./cop/tmpFiles";
import messages from "./cop/message";

/**
 * Combines all the reducers into the root reducer.
 * @param {object} history - The history object used for router state synchronization
 * @returns {function} - The root reducer function
 */
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18nState: defaulti18nState(history),
    servWork,
    notifications,
    xapis,
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded reducers
    faqs,
    faqcategories,
    fonts,
    landingpages,
    editorSize,
    editorShop,
    editorFile,
    shopProducts,
    shopTaxons,
    shopCart,
    shopCheckout,
    shopAdyen,
    shopAmazonPay,
    shopPayPalPay,
    shopCountries,
    shopErrors,
    formSteps,
    formStepsOptions,
    cases,
    cmsblocks,
    blogPosts,
    orderReviews,
    lineItemProblems,
    lineItemFeedbacks,
    lineItemProblemSolutions,
    messages,
    tmpFiles,
    form: formReducer, // redux-form reducer
  });

export default rootReducer;
