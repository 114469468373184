import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { render, hydrate } from "react-dom";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import Loadable from "react-loadable";
import { createSsr } from "@issr/core";
import I18n from "redux-i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import configureStore from "./redux/store";
import rootSaga from "./redux/rootSaga";
import Root from "./routes/Root";
// import configureWorker from "./utils/serviceWorkerConfig";

import "./index.sass";

/** Server-Side Rendering Setup */
const SSR = createSsr();

/** Configure Redux store and history */
const { store, history } = configureStore();

/** Run Redux Saga middleware */
store.runSaga(rootSaga);



const Application = () => {
  const { translations, lang } = useSelector((state) => state.i18nState);
  return (
    <I18n translations={translations} initialLang={lang}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </I18n>
  );
};

/** Root element in the DOM */
const rootElement = document.querySelector("#root");

/** Render or Hydrate the React application */
const renderApp = (Component) => {
  if (rootElement.hasChildNodes()) {
    // SSR: Hydrate to attach event listeners after server-rendered HTML
    Loadable.preloadReady().then(() => {
      hydrate(
        <SSR>
          <Provider store={store}>
            <Component />
          </Provider>
        </SSR>,
        rootElement
      );
    });
  } else {
    // Client-side render
    render(
      <SSR>
        <Provider store={store}>
          <Component />
        </Provider>
      </SSR>,
      rootElement
    );
  }
};

renderApp(Application);

/** Service Worker Configuration */
if (!configureStore.isServer) {
  serviceWorkerRegistration.unregister();
  // Uncomment the next line to enable service worker for offline support
  // serviceWorkerRegistration.register(configureWorker(store));
} else {
  serviceWorkerRegistration.unregister();
}
