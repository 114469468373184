import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import PaymentMethodBadges from "../ShopProduct/utils/PaymentMethodBadges";

/**
 * The Footer component that renders the footer sections.
 * @param {Object} props The props passed to the footer component.
 */
const Footer = (props, context) => {
  const { lang, isMinimalFooter, setEnforcePrivacyConsentModal } = props;

  // Helper function to render multiple links with optional language filtering
  const renderLinks = (links) =>
    links.map(({ url, title, languages }) => {
      if (!languages || languages.includes(lang)) {
        return (
          <li key={url}>
            <Link to={`/${lang}${url}`}>{title}</Link>
          </li>
        );
      }
      return null; // If language doesn't match, don't render the link
    });

  return (
    <footer className="footer">
      <div className="footer__top"></div>

      {!isMinimalFooter && (
        <Container className="footer__container">
          <Row>
            {/* Customer Service Block */}
            <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">
                  {context.t("Customer Service")}
                </span>
              </div>
              <div className="footer__block__separator"></div>
              <ul>
                {renderLinks([
                  {
                    url: `/faq/was-ist-klebefolie/`,
                    title: context.t("What is adhesive foil?"),
                  },
                  {
                    url: `/p/montage/`,
                    title: context.t(
                      "Installation instruction for all products"
                    ),
                  },
                  {
                    url: `/faq/montage-klebeschrift/`,
                    title: context.t(
                      "Installation instruction for adhesvive lettering"
                    ),
                  },
                  {
                    url: `/p/versandarten/`,
                    title: context.t("Shipping & Delivery"),
                  },
                  {
                    url: `/p/zahlungsarten/`,
                    title: context.t("Payment Methods"),
                  },
                  {
                    url: `/kontakt/`,
                    title: context.t("Contact"),
                  },
                  {
                    url: `/blog/`,
                    title: context.t("Blog"),
                  },
                  {
                    url: `/faqs/`,
                    title: context.t("FAQs / frequently asked questions"),
                  },
                ])}
              </ul>
            </Col>

            {/* Applications Block */}
            <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">
                  {context.t("Applications")}
                </span>
              </div>
              <div className="footer__block__separator"></div>
              <ul>
                {renderLinks([
                  {
                    url: `/p/autobeschriftung/`,
                    title: context.t("Car labelling"),
                  },
                  {
                    url: `/p/autoaufkleber/`,
                    title: context.t("Car sticker"),
                  },
                  {
                    url: `/p/fliesenfolie/`,
                    title: context.t("tile foil"),
                    languages: ["de"], // Only render for German language
                  },
                  {
                    url: `/p/fototapete/`,
                    title: context.t("photo wallpaper"),
                  },
                  {
                    url: `/p/fotoleinwand/`,
                    title: context.t("photo canvas"),
                  },
                  {
                    url: `/p/lochfolie/`,
                    title: context.t("perforated foil"),
                  },
                  {
                    url: `/p/magnetfolie/`,
                    title: context.t("magnetic foil"),
                  },
                  {
                    url: `/p/milchglasfolie/`,
                    title: context.t("frosted glass foil"),
                  },
                  {
                    url: `/p/moebelfolie/`,
                    title: context.t("furniture foil"),
                  },
                  {
                    url: `/p/klebebuchstaben/`,
                    title: context.t("adhesive letters"),
                  },
                  {
                    url: `/p/schaufensterbeschriftung/`,
                    title: context.t("Shop window labeling"),
                  },
                  {
                    url: `/p/heckscheibenaufkleber/`,
                    title: context.t("rear window sticker"),
                  },
                  {
                    url: `/klebeschriften/`,
                    title: context.t("Vehicle Tagging"),
                  },
                  {
                    url: `/p/messebeschriftung/`,
                    title: context.t("Fair labelling"),
                  },
                  {
                    url: `/p/bootsbeschriftung/`,
                    title: context.t("Boat labelling"),
                  },
                  {
                    url: `/p/schilderbeschriftung/`,
                    title: context.t("Sign labelling"),
                  },
                  {
                    url: `/p/fahrzeugbeschriftung/`,
                    title: context.t("Vehicle labelling"),
                  },
                  {
                    url: `/p/statischefolie/`,
                    title: context.t("Static foil"),
                  },
                ])}
              </ul>
            </Col>

            {/* Company Block */}
            <Col xs={12} sm={6} md={3} xl={3} className="footer__block">
              <div className="footer__block__title mb-2">
                <span className="h5 text-uppercase">
                  {context.t("Company")}
                </span>
              </div>
              <div className="footer__block__separator"></div>
              <div className="footer__contact">
                <p className="footer__contact__mail">
                  <Link to={`/kontakt/`} className="text-white">
                    <FaEnvelope className="fa-lg fa-fw" />
                    <span className="ml-3">{context.t("contact form")}</span>
                  </Link>
                </p>
                <ul>
                  {renderLinks([
                    { url: `/p/impressum/`, title: context.t("Imprint") },
                    {
                      url: `/p/datenschutz/`,
                      title: context.t("Data privacy"),
                    },
                  ])}
                  <li>
                    <a
                      href="#privacy"
                      onClick={() => setEnforcePrivacyConsentModal(true)}
                    >
                      {context.t("Dataprivacy Settings")}
                    </a>
                  </li>
                  {renderLinks([
                    {
                      url: `/p/agb/`,
                      title: context.t("Terms and conditions"),
                    },
                  ])}
                </ul>
              </div>
              <Link to={`/${lang}/p/zahlungsarten/`}>
                <PaymentMethodBadges />
              </Link>
            </Col>
          </Row>
        </Container>
      )}

      <div className="footer__copyright bg-dark py-4">
        <span className="small">
          &copy; {context.t("Copyright 2011-2022 myfolie GmbH")}
        </span>
        {isMinimalFooter && (
          <span className="small ml-3">
            {renderLinks([
              { url: `/p/impressum/`, title: context.t("Imprint") },
              { url: `/p/datenschutz/`, title: context.t("Data privacy") },
            ])}
            |{" "}
            <a
              href="#privacy"
              onClick={() => setEnforcePrivacyConsentModal(true)}
            >
              {context.t("Dataprivacy Settings")}
            </a>{" "}
            |{" "}
            {renderLinks([
              {
                url: `/p/agb/`,
                title: context.t("Terms and conditions"),
              },
            ])}
          </span>
        )}
      </div>
    </footer>
  );
};

Footer.contextTypes = {
  t: PropTypes.func,
  isMinimalFooter: PropTypes.bool,
};

export default Footer;
