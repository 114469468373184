/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

 import { put, takeEvery, all } from 'redux-saga/effects';
 
 import { actions as orderReviewActions } from '../order_reviews';
 import { actions as lineItemProblemsActions } from '../line_item_problem';
 import { actions as lineItemFeedbacksActions } from '../line_item_feedback';
 import { actions as lineItemFeedbackSolutionsActions } from '../line_item_problem_solution';
 import { actions as messagesActions } from '../message';
 
 /** Worker Sagas */
 export function* storeIncluded(action) {
   yield all(
     action?.payload?.map((entry) => {
       if (entry.type === 'line_item_feedback') {
         return put({ type: lineItemFeedbacksActions.addToDictionary, payload: entry });
       }
       if (entry.type === 'line_item_problem') {
         return put({ type: lineItemProblemsActions.addToDictionary, payload: entry });
       }
       if (entry.type === 'line_item_problem_solution') {
        return put({ type: lineItemFeedbackSolutionsActions.addToDictionary, payload: entry });
       }
       if (entry.type === 'message') {
        return put({ type: messagesActions.addToDictionary, payload: entry });
       }
       if (entry.type === 'order_review') {
         return put({ type: orderReviewActions.addToDictionary, payload: entry });
       } else return null;
     }),
   );
 }
 
 /**
  * Saga Watchers
  * The exported list of sagas registered. When one of the action types is dispatched
  * the related worker saga is invoked.
  * Each saga is executed in a different thread
  */
 function* dictionarySagas() {
   yield takeEvery('dictionary/storeIncluded', storeIncluded);
 }
 export default dictionarySagas;