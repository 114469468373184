/**
 * The CheckoutErrorModal component
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// export function useAmazonPay() {
//   const [amazonPay, setAmazonPay] = useState(null);

//   useEffect(() => {
//     const _window = window;
//     const amazonApi = _window?.amazon?.Pay;
//     if (amazonApi) {
//       // set only if amazon pay is not set
//       if (!amazonPay) {
//         setAmazonPay({ ...amazonApi });
//       }
//     }
//   });

//   return { amazonPay: { ...amazonPay } };
// }

export function getParams(location) {
  const search = location.search.substring(1);
  if (search.length > 0) {
    const entries = search.split("&");
    const pramsList = entries.map((entry) => {
      const [key, value] = entry.split("=");
      return { [key]: value };
    });
    const params = Object.assign({}, ...pramsList);
    return params;
  }
}

/**
 * Stateless react component to render the CheckoutErrorModal component
 * @param {Object} props the props passed to the page.
 */
const AmazonPayButton = (props, context) => {
  const {
    merchantId,
    publicKeyId,
    amazonButtonConfig,
    buttonMode,
    amazonCheckoutSessionId,
  } = props;
  const [amazonScriptLoaded, setAmazonScriptLoaded] = useState(false);
  const [amazonPay, setAmazonPay] = useState(null);

  const amazonApi = window.amazon?.Pay;

  useEffect(() => {
    if (amazonApi) {
      if (!amazonPay) {
        setAmazonPay({ ...amazonApi });
      }
    }
  }, [amazonApi, amazonPay, amazonScriptLoaded]);

  useEffect(() => {
    if (amazonScriptLoaded) {
      return;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://static-eu.payments-amazon.com/checkout.js";
    script.crossorigin = "anonymous";
    script.async = true;
    document.body.appendChild(script);
    setTimeout(() => {
      setAmazonScriptLoaded(true);
    }, 1000);
  }, []);

  const renderAmazonPayButton = async () => {
    if (!amazonPay?.renderButton) {
      return;
    }

    /**
     * AmazonButtonConfig includes payload and signature
     * They are necessary to make POST requests to the server
     */

    if (!amazonButtonConfig) {
      return;
    }
    const { payload, signature } = amazonButtonConfig;
    // const signature =  amazonPay.generateButtonSignature(payload);
    const createCheckoutSessionConfig = {
      payloadJSON: JSON.stringify(payload),
      signature: signature,
      publicKeyId: publicKeyId, // TODO ENV or API?
    };

    const amazonPaySetting = {
      merchantId: merchantId,
      ledgerCurrency: "EUR",
      checkoutLanguage: "de_DE",
      productType: "PayAndShip",
      placement: "Checkout",
      buttonColor: "Gold",
      createCheckoutSessionConfig,
    };

    amazonPay.renderButton("#AmazonPayButton", amazonPaySetting);
  };

  useEffect(() => {
    if (!buttonMode || buttonMode === "connect") renderAmazonPayButton();
  }, [amazonButtonConfig, amazonPay]);

  useEffect(() => {
    if (!amazonCheckoutSessionId || !amazonPay) return;
    if (buttonMode && buttonMode === "payment") renderPaymentEditButton();
    else if (buttonMode && buttonMode === "address") renderAddressEditButton();
  }, [amazonCheckoutSessionId, amazonPay, buttonMode]);

  const renderPaymentEditButton = async () => {
    if (!amazonPay?.bindChangeAction) {
      return;
    }

    if (!amazonCheckoutSessionId) {
      return;
    }

    amazonPay.bindChangeAction("#AmazonChangeActionPayment", {
      changeAction: "changePayment",
      amazonCheckoutSessionId,
    });
  };

  const renderAddressEditButton = async () => {
    if (!amazonPay?.bindChangeAction) {
      return;
    }

    if (!amazonCheckoutSessionId) {
      return;
    }

    amazonPay.bindChangeAction("#AmazonChangeActionAddress", {
      changeAction: "changeAddress",
      amazonCheckoutSessionId,
    });
  };
  return (
    <>
      {(!buttonMode || buttonMode === "connect") && (
        <div id="AmazonPayButton"></div>
      )}
      {buttonMode && buttonMode === "payment" && (
        <div
          id="AmazonChangeActionPayment"
          className="btn btn-link btn-block btn-sm"
        >
          {context.t("change payment")}
        </div>
      )}
      {buttonMode && buttonMode === "address" && (
        <div
          id="AmazonChangeActionAddress"
          className="btn btn-link btn-block btn-sm"
        >
          {context.t("change address")}
        </div>
      )}
    </>
  );
};

/**  define proptype for the 'translation' function  */
AmazonPayButton.contextTypes = {
  t: PropTypes.func,
};

export default AmazonPayButton;
