/**
 * The Page component
 */
import React from "react";
import Helmet from "react-helmet";
import { REACT_APP_GTM, REACT_APP_CDN_SERVER } from "../../../utils/env";
import Cookies from "universal-cookie";
import { isServer } from "../../../redux/store";
const cookieHandler = new Cookies();

/**
 * Helper function to retrieve consent cookie
 */
const getConsentCookie = () => {
  let consent = cookieHandler.get("privacy-consent");
  return consent || cookieHandler.get("privacy-consent-tmp");
};

/**
 * Helper function to get GTM script
 */
const getGtmScript = (gtmId) => `
  (function(w,d,s,l,i){
    w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');
`;

/**
 * Helper function to get script tags based on privacy consent
 */
const getScriptTags = () => {
  const consentCookieContent = getConsentCookie();
  if (isServer) return;
  const scripts = [
    {
      innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag("consent", "default", {
          ad_storage: "${
            consentCookieContent?.advertising ? "granted" : "denied"
          }",
          ad_user_data: "${
            consentCookieContent?.advertising ? "granted" : "denied"
          }",
          ad_personalization: "${
            consentCookieContent?.advertising ? "granted" : "denied"
          }",
          analytics_storage: "${
            consentCookieContent?.analyze ? "granted" : "denied"
          }",
          personalization_storage: "${
            consentCookieContent?.customization ? "granted" : "denied"
          }",
          functionality_storage: "granted",
          security_storage: "granted",
          wait_for_update: 1000
        });
        gtag("set", "ads_data_redaction", true);
      `,
    },
  ];

  // Add GTM script if consent is given and GTM ID exists
  if (
    (consentCookieContent?.advertising || consentCookieContent?.analyze) &&
    REACT_APP_GTM
  ) {
    scripts.push({ innerHTML: getGtmScript(REACT_APP_GTM) });
  }

  return scripts;
};

/** generate Links */
const getStaticLinkTags = () => {
  const linkTags = [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: `${REACT_APP_CDN_SERVER}/favicon/apple-touch-icon.png`,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: `${REACT_APP_CDN_SERVER}/favicon/favicon-32x32.png`,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: `${REACT_APP_CDN_SERVER}/favicon/favicon-16x16.png`,
    },
    { rel: "manifest", href: `${REACT_APP_CDN_SERVER}/manifest.json` },
    {
      rel: "mask-icon",
      color: "#169dce",
      href: `${REACT_APP_CDN_SERVER}/favicon/safari-pinned-tab.svg`,
    },
    {
      rel: "shortcut icon",
      href: `${REACT_APP_CDN_SERVER}/favicon/favicon.ico`,
    },
  ];
  return linkTags;
};

/**
 * Page Component
 * - sets SEO data for each page
 * - used to wrap routes
 */
const StaticHeader = () => {
  const scripts = getScriptTags();
  const links = getStaticLinkTags();

  return (
    <Helmet script={scripts} links={links}>
      {/* Add other possible meta tags or elements */}
    </Helmet>
  );
};

// PropTypes validation
StaticHeader.propTypes = {};

export default StaticHeader;
