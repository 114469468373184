// locales/fetchTranslations.js
import { REACT_APP_CDN_SERVER } from "./env";

const translationCache = {};

export const fetchTranslations = async (lang) => {
  // Check if the language is already loaded in the cache
  if (translationCache[lang]) {
    return translationCache[lang];
  }

  try {
    const response = await fetch(
      `${REACT_APP_CDN_SERVER}/locales/${lang}.json`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Correctly return the translation object
    const translations = await response.json();
    translationCache[lang] = { [lang]: translations };
    return { [lang]: translations };
  } catch (error) {
    console.error(`Error loading translations for language ${lang}:`, error);
    // Optionally, you can load a fallback language or return an empty object
    return { [lang]: {} };
  }
};
