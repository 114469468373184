/**
 * The navbar container
 */
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import flag_de from "../../assets/country-flags/de.svg";
import flag_es from "../../assets/country-flags/es.svg";
import flag_it from "../../assets/country-flags/it.svg";
import flag_uk from "../../assets/country-flags/uk.svg";
import icon_checkmark from "../../assets/images/icons/verify-circular-black-button-symbol.svg";
import LazyLoad from "react-lazyload";
import { forceCheck } from "react-lazyload";
import { Link } from "react-router-dom";
import logo from "../../assets/images/myfolie-logo.svg";

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  location: state.router.location,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: setLanguage,
      navigateTo: push,
    },
    dispatch
  );

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const TopBar = (props, context) => {
  const [isOpen, toggleIsOpen] = useState(false);

  /** toggles the open state of the navbar */
  const toggle = () => {
    toggleIsOpen(!isOpen);
    forceCheck();
  };

  /**
   * List of languages supported
   */
  const languages = {
    it: "Italiano",
    en: "English",
    de: "Deutsch",
    es: "español",
  };
  const flags = {
    it: flag_it,
    en: flag_uk,
    de: flag_de,
    es: flag_es,
  };

  const { lang, location, isCheckout } = props;

  /** handles the change language event */
  const handleI18nAction = (newLanguage, event) => {
    event.preventDefault();
    var newPath = location.pathname.replace(`/${lang}/`, `/${newLanguage}/`);
    props.setLanguage(newLanguage);
    props.navigateTo(newPath);
  };

  let myfolieBaseURL = `/${lang}`;
  return (
    <div style={props.style} className={classNames("topbar")}>
      <Container className={`d-flex${isCheckout ? " bg-white" : ""}`}>
        {isCheckout && (
          <>
            <span className="d-flex align-items-center">
              <Link to={`/${lang}/checkout/cart/`}>
                &laquo; {context.t("back to cart")}
              </Link>
            </span>
            <span className="flex-fill text-center">
              <Link to={`${myfolieBaseURL}/`} className="navbar-brand">
                <img
                  src={logo}
                  width="109"
                  height="31"
                  data-src-retina={logo}
                  className="logo"
                  title="myfolie.com"
                  alt="myfolie.com"
                />
              </Link>
            </span>
          </>
        )}
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle
            nav
            caret
            onClick={toggle}
            data-toggle="dropdown"
            aria-expanded={isOpen}
          >
            <img
              aria-label="check"
              width="19"
              height="11"
              src={flags[lang]}
              alt={lang}
              title={lang}
              loading="lazy"
            />
            &nbsp;
            <span className="d-none d-sm-inline">{languages[lang]}</span>
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(languages).map((language) => {
              if (language !== lang) {
                return (
                  <DropdownItem
                    key={language}
                    onClick={handleI18nAction.bind(this, language)}
                  >
                    <LazyLoad once>
                      <img
                        aria-label="check"
                        width="19"
                        height="11"
                        src={flags[language]}
                        alt={language}
                        title={language}
                        loading="lazy"
                      />
                      &nbsp;
                    </LazyLoad>
                    {languages[language]}
                  </DropdownItem>
                );
              } else return "";
            })}
          </DropdownMenu>
        </Dropdown>
        {!isCheckout && (
          <>
            <span className="ml-3 small d-none d-sm-flex align-items-center">
              <img
                height="14"
                width="14"
                src={icon_checkmark}
                alt="✓"
                title="✓"
              />
              &nbsp;{context.t("delivered ready to install")}
            </span>
            <span className="ml-3 small d-none d-sm-flex align-items-center">
              <img
                height="14"
                width="14"
                src={icon_checkmark}
                alt="✓"
                title="✓"
              />
              &nbsp;{context.t("fast production and shipping")}
            </span>
            <span className="ml-3 small d-none d-sm-flex align-items-center">
              <img
                height="14"
                width="14"
                src={icon_checkmark}
                alt="✓"
                title="✓"
              />
              &nbsp;
              {context.t("Pay securely with paypal (incl. buyer protection)")}
            </span>
            <span className="ml-3 small d-none d-sm-flex align-items-center">
              🤝 {context.t("13+ years' experience, 350,000+ customers served")}
            </span>
            {/*<span className="ml-3 small d-none d-sm-flex align-items-center justify-content-end topbar__inquiry"><a className="text-bold" href={`${myfolieBaseURL}/individuelle-anfrage/`}>{context.t("Your Inquiry")}</a></span> */}
          </>
        )}
      </Container>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
TopBar.contextTypes = {
  t: PropTypes.func,
  isCheckout: PropTypes.bool,
};

// export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
/** export the component as redux connected component */
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
