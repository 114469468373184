import React from "react";
import classNames from "classnames";

/**
 * React component to scroll to the top when navigating to a new route
 */
const ResponsivePicture = ({
  className,
  mediumData,
  altTitle,
  skipLazyLoading,
  cover,
}) => {
  return (
    <picture className={classNames("mw-100 mh-100 d-flex", className)}>
      {mediumData?.webp_url && (
        <source
          media={mediumData.media || "(min-width: 1px)"}
          srcSet={
            "//" +
            mediumData.webp_url.replace("https://", "").replace("http://", "")
          }
          type="image/webp"
        />
      )}
      {mediumData?.url && (
        <source
          media={mediumData.media || "(min-width: 1px)"}
          srcSet={
            "//" + mediumData.url.replace("https://", "").replace("http://", "")
          }
          type={mediumData?.content_type}
        />
      )}
      {mediumData?.sources?.map((image, idx) => {
        return (
          <React.Fragment key={idx}>
            {image.webp_url && (
              <source
                media={image.media || "(min-width: 1px)"}
                srcSet={
                  (/^https?:\/\//.test(image.webp_url) ? "//" : "") +
                  image?.webp_url
                    ?.replace("https://", "")
                    .replace("http://", "")
                }
                type="image/webp"
              />
            )}
            {image.url && (
              <source
                media={image.media || "(min-width: 1px)"}
                srcSet={
                  (/^https?:\/\//.test(image.url) ? "//" : "") +
                  image.url.replace("https://", "").replace("http://", "")
                }
                type={image?.content_type}
              />
            )}
          </React.Fragment>
        );
      })}

      <img
        srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        className="img-fluid"
        loading={skipLazyLoading ? "eager" : "lazy"}
        style={
          cover
            ? { objectFit: "cover" }
            : { objectFit: "contain", margin: "0 auto" }
        }
        height={mediumData?.metadata?.height}
        width={mediumData?.metadata?.width}
        alt={altTitle}
      />
    </picture>
  );
};

export default ResponsivePicture;
