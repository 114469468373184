/**
 * Window dataLayer Helper
 */
import Cookies from "universal-cookie";
import { isServer } from "../redux/store";

const cookieHandler = new Cookies();

const dataLayer = () => {
  return !isServer ? window.dataLayer || [] : [];
};

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
}

const addEvent = (event, action, label = "", value = 0) => {
  let dl = dataLayer();
  dl.push({
    event,
    action,
    label,
    value,
  });
};

const addEventWithOrigin = (
  event,
  eventOrigin,
  action,
  label = "",
  value = 0
) => {
  let dl = dataLayer();
  dl.push({
    event,
    eventOrigin,
    action,
    label,
    value,
  });
};

const addRedirectEvent = (action, label, value = 0) => {
  let dl = dataLayer();
  dl.push({
    event: "redirect",
    action,
    label,
    value,
  });
};

const addSuccessEvent = (action, label, value = 0) => {
  let dl = dataLayer();
  dl.push({
    event: "success",
    action,
    label,
    value,
  });
};

const addErrorEvent = (action, label, value = 0) => {
  let dl = dataLayer();
  dl.push({
    event: "error",
    action,
    label,
    value,
  });
};

const addFormErrorsEvent = (action, errors) => {
  let dl = dataLayer();

  Object.keys(errors).forEach((errorKey) => {
    let fieldErrors = errors[errorKey];
    let label = `${errorKey} - ${fieldErrors.join(",")}`;
    dl.push({
      event: "error",
      action,
      label,
      value: 0,
    });
  });
};

const addCheckoutEvent = (action, label, value = 0) => {
  let dl = dataLayer();
  dl.push({
    event: "checkout",
    action,
    label,
    value,
  });
};

const addToCartEvent = (options) => {
  let dl = dataLayer();
  dl.push({
    event: "add_to_cart",
    value: options.price * (options.amount || options.quantity),
    items: [
      {
        name: options.productTitle || options.name,
        id: options.sku,
        price: options.price,
        brand: "myfolie",
        quantity: options.amount || options.quantity,
        google_business_vertical: "retail",
      },
    ],
  });
  dl.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "EUR",
      add: {
        products: [
          {
            name: options.productTitle || options.name,
            id: options.sku,
            price: options.price,
            brand: "myfolie",
            quantity: options.amount || options.quantity,
            // 'category': 'Apparel',
            // 'variant': 'Gray',
          },
        ],
      },
    },
  });
};

const removeFromCartEvent = (options) => {
  let dl = dataLayer();
  dl.push({
    event: "removeFromCart",
    ecommerce: {
      currencyCode: "EUR",
      remove: {
        products: [
          {
            name: options.productTitle || options.name,
            id: options.sku,
            price: options.price,
            brand: "myfolie",
            quantity: options.amount || options.quantity,
            // category': 'Apparel',
            // 'variant': 'Gray',
          },
        ],
      },
    },
  });
};

const addPurchaseEvent = (payload) => {
  let actionField_affiliation = "myfolie GmbH";
  if (
    cookieHandler.get("myfsource") === "adcell" ||
    cookieHandler.get("myfrtsource") === "adcell" ||
    cookieHandler.get("myfsource") === "belboon" ||
    cookieHandler.get("myfrtsource") === "belboon"
  ) {
    if (cookieHandler.get("myfrtsource")) {
      actionField_affiliation =
        cookieHandler.get("myfrtsource") +
        " / " +
        cookieHandler.get("myfrtaffiliate") +
        " (retargeting)";
    } else {
      actionField_affiliation =
        cookieHandler.get("myfsource") +
        " / " +
        cookieHandler.get("myfaffiliate");
    }
  }
  const purchaseProducts = getProductsFromIncluded(payload.included);

  digestMessage(payload.data.attributes.email).then((hashedRef) => {
    let dl = dataLayer();
    dl.push({
      event: "purchase",
      ecommerce: {
        currencyCode: "EUR",
        purchase: {
          actionField: {
            id: payload.data.attributes.number, // Transaction ID. Required for purchases and refunds.
            affiliation: actionField_affiliation,
            revenue: payload.data.attributes.total, // Total transaction value (incl. tax and shipping)
            tax: payload.data.attributes.tax_total,
            shipping: payload.data.attributes.ship_total,
            ref: hashedRef,
            //'coupon': 'SUMMER_SALE'
          },
          products: purchaseProducts,
        },
      },
    });
  });
};

const addCheckoutStepEvent = (payload, step, option) => {
  const products = getProductsFromIncluded(payload.included);
  let dl = dataLayer();
  let actionField = actionFieldForStep[step] || {
    step: 0,
    option: `${step} not found`,
  };
  dl.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: actionField,
        products: products,
      },
    },
    eventCallback: function () {
      // document.location = 'checkout.html';
    },
  });
};

const addCheckoutStepOptionEvent = (step, option) => {
  let dl = dataLayer();
  let actionField = actionFieldForStep[step] || {
    step: 0,
    option: `${step} not found`,
  };
  actionField.option = option;
  dl.push({
    event: "checkoutOption",
    ecommerce: {
      checkout_option: {
        actionField: actionField,
      },
    },
  });
};

const actionFieldForStep = {
  address: { step: 1, option: "address" },
  delivery: { step: 2, option: "delivery" },
  payment: { step: 3, option: "payment" },
  confirm: { step: 4, option: "confirm" },
  complete: { step: 5, option: "complete" },
};

const getProductsFromIncluded = (payloadIncluded) => {
  let products = [];
  payloadIncluded
    .filter((item) => {
      return item.type === "line_item";
    })
    .forEach((product) => {
      let category = "";
      const variantId =
        product.relationships &&
        product.relationships.variant &&
        product.relationships.variant.data &&
        product.relationships.variant.data.id;
      if (variantId) {
        let variant = payloadIncluded.find(
          (inc) => inc.type === "variant" && inc.id === variantId
        );
        let variantProductId =
          variant &&
          variant.relationships &&
          variant.relationships.product &&
          variant.relationships.product.data &&
          variant.relationships.product.data.id;
        if (variantProductId) {
          let variantProduct = payloadIncluded.find(
            (inc) => inc.type === "product" && inc.id === variantProductId
          );
          if (variantProduct && variantProduct.attributes) {
            category = variantProduct.attributes.product_type;
          }
        }
      }
      let parsedProduct = {
        name: product.attributes.name,
        id: product.attributes.sku,
        price: product.attributes.price,
        brand: "myfolie",
        quantity: product.attributes.quantity,
        category,
      };

      if (product.attributes.project_uid)
        parsedProduct.variant = product.attributes.project_uid;
      // TODO check if this really helps or generates more noise in tracking

      products.push(parsedProduct);
    });
  return products;
};

/** exports all actions as default */
const dataLayerEvents = {
  addRedirectEvent: addRedirectEvent,
  addSuccessEvent: addSuccessEvent,
  addErrorEvent: addErrorEvent,
  addCheckoutEvent: addCheckoutEvent,
  addCheckoutStepEvent: addCheckoutStepEvent,
  addToCartEvent: addToCartEvent,
  removeFromCartEvent: removeFromCartEvent,
  addPurchaseEvent: addPurchaseEvent,
  addEvent: addEvent,
  addEventWithOrigin: addEventWithOrigin,
  addFormErrorsEvent: addFormErrorsEvent,
  addCheckoutStepOptionEvent: addCheckoutStepOptionEvent,
};

export default dataLayerEvents;
