import buildHeaders from "../../../utils/buildHeaders";
import Swagger from "swagger-client";
import { actions } from "./index";
import { call, put, takeEvery } from "redux-saga/effects";
import { REACT_APP_REVIEW_API_SERVER } from "../../../utils/env";

export function* create(action) {
  let headers = yield buildHeaders();

  const { successCallback, tmpFileData, values } = action.payload;

  try {
    const payload = yield call(
      apiRequest,
      `${REACT_APP_REVIEW_API_SERVER}/tmp_files`,
      {
        body: JSON.stringify(values),
        method: "POST",
        headers,
      }
    );
    if (successCallback) successCallback(payload.data.id);
    yield put(actions.createSuccess({ ...payload.data, raw: tmpFileData }));
  } catch (e) {
    console.error(e);
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}

const apiRequest = (_url, args) => {
  const request = {
    url: _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  };
  return Swagger.http(request)
    .then((res) => {
      return res.body;
    })
    .catch((err) => {
      console.log("ERROR IN APIREQUEST");
      console.log(err);
      throw err;
    });
};

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* tmpFilesSaga() {
  yield takeEvery(actions.create, create);
}
export default tmpFilesSaga;
