/**
 * Sagas for the notifications managment
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./index";
import { isServer } from "../../store";
import apiShopRequest from "../utils/apiShopRequest";
import buildHeaders from "../../../utils/buildHeaders";
import { list as loadCmsBlocks } from "../../cmsblocks/sagas";
import { list as loadFaqs } from "../../faqs/sagas";
import DataLayerHelper from "../../../utils/dataLayer";
import { push } from "connected-react-router";
/** Worker Sagas */

export function* list(action) {
  const { filters, page, perPage } = action.payload;
  let requestFilters = "";
  filters.map((filter) => {
    requestFilters += `&filter[${filter.key}]=${filter.value}`;
    return true;
  });
  /** if we are offline we use persisted data */
  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedProducts = yield select((state) => state.products.stored);
    Object.keys(storedProducts).forEach((storedProductIndex) => {
      storedList.push(Object.assign({}, storedProducts[storedProductIndex]));
    });
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const payload = yield call(
        apiShopRequest,
        `/api/v2/storefront/products.json?include=taxons,images${requestFilters}&page=${
          page || 1
        }&per_page=${perPage || 40}&lang=${lang}`,
        { method: "GET", headers: headers, useSdn: true }
      );
      payload.language = lang;
      payload.requestFilters = requestFilters;

      yield put(actions.listProductsSuccess({ ...payload, language: lang }));
    } catch (e) {
      console.error("ERROR", e);
      yield put(actions.listProductsFail(e));
      DataLayerHelper.addErrorEvent(
        "listProducts",
        e?.error || e?.message || "Shop API Failure"
      );
    }
  }
}

export function* search(action) {
  const { filters, page, perPage, searchType } = action.payload;
  let requestFilters = "";
  filters.map((filter) => {
    requestFilters += `&filter[${filter.key}]=${filter.value}`;
    return true;
  });

  if (searchType) requestFilters += `&search_type=${searchType}`;
  // Get the current page's path
  const path = isServer ? "server" : window.location.pathname;
  // URL-encode the path
  const encodedPath = encodeURIComponent(path);
  if (encodedPath) requestFilters += `&source=${encodedPath}`;
  /** if we are offline we use persisted data */
  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedProducts = yield select((state) => state.products.stored);
    Object.keys(storedProducts).forEach((storedProductIndex) => {
      storedList.push(Object.assign({}, storedProducts[storedProductIndex]));
    });
    yield put({ type: actions.searchSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    let headers = buildHeaders();
    const lang = yield select((state) => state.i18nState.lang);
    headers["Accept-Language"] = lang;
    try {
      const payload = yield call(
        apiShopRequest,
        `/api/v2/storefront/products.json?include=taxons,images${requestFilters}&page=${page}&per_page=${
          perPage || 40
        }&lang=${lang}`,
        { method: "GET", headers: headers, useSdn: false }
      );
      payload.language = lang;
      payload.requestFilters = requestFilters;
      yield put(actions.searchProductsSuccess({ ...payload, language: lang }));
    } catch (e) {
      console.error("ERROR", e);
      yield put(actions.searchProductsFail(e));

      DataLayerHelper.addErrorEvent(
        "searchProducts",
        e?.error || e?.message || "Shop API Failure"
      );
    }
  }
}

export function* show(action) {
  const { slug, successCallback } = action.payload;
  let headers = buildHeaders();
  const lang = yield select((state) => state.i18nState.lang);
  headers["Accept-Language"] = lang;
  try {
    const payload = yield call(
      apiShopRequest,
      `/api/v2/storefront/products/${slug}.json?include=taxons,images,variants,default_variant,product_properties,option_types,variants.option_values,default_variant.model_volume_prices&lang=${lang}`,
      { method: "GET", headers: headers, useSdn: true }
    );
    yield put(actions.showProductSuccess({ ...payload, language: lang }));
    if (successCallback) successCallback(payload);
    return payload;
  } catch (e) {
    console.error("PRODUCT SHOW ERROR", e);
    DataLayerHelper.addErrorEvent(
      "showProduct",
      e?.error || e?.message || "Shop API Failure"
    );

    if (
      e?.responseStatus === 404 ||
      e?.responseStatus === 403 ||
      e?.responseStatus === 417
    ) {
      yield put(
        actions.showProductFail({
          message: e?.message,
          ...e,
          errorRedirect:
            "/" +
            lang +
            "/404-product/?source=" +
            slug +
            "&status=" +
            e?.responseStatus,
        })
      );
      yield put(
        push(
          "/" +
            lang +
            "/404-product/?source=" +
            slug +
            "&status=" +
            e?.responseStatus
        )
      );
    } else {
      yield put(actions.showProductFail(e));
    }
  }
}

export function* showProductAndLoadCMSBlocks(action) {
  const payload = yield* show(action);

  if (payload?.data?.attributes?.cms_blocks) {
    yield* loadCmsBlocks({
      payload: { slugs: payload.data.attributes.cms_blocks },
    });
  }
  if (payload?.data?.attributes?.faq_slugs) {
    yield* loadFaqs({
      payload: { slugs: payload.data.attributes.faq_slugs },
    });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* shopProductsSaga() {
  yield takeEvery(actions.listProducts, list);
  yield takeEvery(actions.showProduct, show);
  yield takeEvery(
    actions.showProductAndLoadCMSBlocks,
    showProductAndLoadCMSBlocks
  );
  yield takeEvery(actions.searchProducts, search);
}

export default shopProductsSaga;
