/**
 * Redux Store Configuration
 */
import { createBrowserHistory, createMemoryHistory } from "history";
import { configureStore as createReduxStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware, { END } from "redux-saga";
import logger from "redux-logger";

import createRootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import i18nMiddleware from "./i18n-middleware";
/** Detect if the current environment is server-side */
export const isServer = typeof window === "undefined";

/**
 * Load the initial state from the window object (if available)
 * @returns {object} - The preloaded state from the server or an empty object
 */
const loadState = () => {
  try {
    if (window?.REDUX_DATA) {
      return window.REDUX_DATA;
    }
    console.log("Preloaded state was empty");
    return {};
  } catch (err) {
    return {};
  }
};

/**
 * Configures and initializes the Redux store
 * @param {string} url - The initial URL for server-side rendering
 * @returns {object} - The store, history, and sagaMiddleware
 */
export default function configureStore(url = "/") {
  const persistedState = isServer ? {} : loadState();

  /** Create the Saga middleware */
  const sagaMiddleware = createSagaMiddleware();

  /** Configure the history object */
  const history = isServer
    ? createMemoryHistory({ initialEntries: [url.split("?")[0]] })
    : createBrowserHistory();

  /** Set up middlewares */
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    i18nMiddleware,
  ];
  if (!isServer) {
    middlewares.push(logger);
  }

  /** Create Redux store */
  const store = createReduxStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: [
            "payload.callbackSuccess",
            "payload.successCallback",
            "payload.successCallbackFnc",
            "payload.errorCallbackFnc",
            "payload.transactionCallbackFnc",
            "payload.callbackFnc",
            "payload.callback",
          ],
        },
      }).concat(middlewares),
    devTools: false, // Disable Redux DevTools by default
    preloadedState: persistedState,
  });

  /** Attach methods for running and closing the Saga */
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { store, history, sagaMiddleware, rootSaga };
}
