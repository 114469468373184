// Load Translations on Language Change

import { fetchTranslations } from "../utils/fetchTranslations";

const i18nMiddleware = (store) => (next) => async (action) => {
  if (action.type === "REDUX_I18N_SET_LANGUAGE") {
    const { lang } = action;
    const state = store.getState();

    if (!state.i18nState?.translations[lang]) {
      try {
        // Fetch the translations for the new language
        let translations = await fetchTranslations(lang);
        translations.options = {
          plural_rule: "n > 1",
          plural_number: "2",
          suppress_warnings: true, // defaults to false
        };
        // Dispatch an action to update the translations in the store
        store.dispatch({
          type: "REDUX_I18N_SET_TRANSLATIONS",
          translations,
        });
      } catch (error) {
        console.error("Error loading translations:", error);
      }
    }
  }

  return next(action);
};
/*
 * Overwrite: redux-i18n state
 */

const languages = ["de", "es", "it", "en"];

export function defaulti18nState(customHistory) {
  const reqLang = customHistory.location.pathname.split("/")[1];
  const defaultLang = languages.includes(reqLang) ? reqLang : "de";

  const reduxI18nState = {
    lang: defaultLang,
    newFallbackLang: null,
    translations: {},
    forceRefresh: false,
  };

  const i18nState = (state = reduxI18nState, action) => {
    switch (action.type) {
      case "REDUX_I18N_SET_LANGUAGE":
        return { ...state, lang: action.lang };
      case "REDUX_I18N_SET_FALLBACK_LANGUAGE":
        return { ...state, newFallbackLang: action.newFallbackLang };
      case "REDUX_I18N_SET_TRANSLATIONS":
        return { ...state, translations: action.translations };
      case "REDUX_I18N_SET_FORCE_REFRESH":
        return { ...state, forceRefresh: action.force };
      default:
        return state;
    }
  };
  return i18nState;
}

export default i18nMiddleware;
