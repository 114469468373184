import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  byIdDictionary: {}, // Stores products by language and ID
  bySlugDictionary: {}, // Stores slugs by language mapping to IDs
  queryDictionary: {}, // Stores queries and their results by language
  list: [],
  listLoading: false,
  listError: false,
  listFilters: {},
  show: null,
  showLoading: false,
  showError: false,
  errorRedirect: null,
  showFourOFour: false,
  searchResults: [],
  currentSearchTerm: null,
};

// const initialState = {
//   productsDictionary: {},

// };

const addProductsToDictionaries = (products, language, state) => {
  if (!state.byIdDictionary[language]) {
    state.byIdDictionary[language] = {};
  }
  if (!state.bySlugDictionary[language]) {
    state.bySlugDictionary[language] = {};
  }

  products.forEach((product) => {
    const { id, attributes: { slug } = {} } = product;
    state.byIdDictionary[language][id] = {
      id: id,
      type: "product",
      data: product,
    };
    if (slug) {
      state.bySlugDictionary[language][slug] = id;
    }
  });
};

const addProductToDictionaries = (product, language, state) => {
  if (!state.byIdDictionary[language]) {
    state.byIdDictionary[language] = {};
  }
  if (!state.bySlugDictionary[language]) {
    state.bySlugDictionary[language] = {};
  }

  const { id, attributes: { slug } = {} } = product.data;
  state.byIdDictionary[language][id] = { id: id, type: "product", ...product };
  if (slug) {
    state.bySlugDictionary[language][slug] = id;
  }
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    listProducts: (state, action) => {
      const { language, filters, productIds } = action.payload;
      let newList = { ...state.list };

      if (language) {
        let requestFilters = filters
          .map((filter) => `&filter[${filter.key}]=${filter.value}`)
          .join("");

        // Check if the filtered list already exists in the query dictionary
        if (
          state.queryDictionary[language] &&
          state.queryDictionary[language][requestFilters]
        ) {
          newList = state.queryDictionary[language][requestFilters];
        }
        // Check if taxon filter has changed
        else if (
          state.listFilters &&
          state.listFilters.find &&
          state.listFilters.find((filt) => filt.key === "taxons") &&
          filters &&
          filters.find((filt) => filt.key === "taxons") &&
          state.listFilters.find((filt) => filt.key === "taxons").value !==
            filters.find((filt) => filt.key === "taxons").value
        ) {
          newList = {};
        }
        // If product IDs are provided, filter products by ID
        else if (
          productIds &&
          productIds.length > 0 &&
          state.byIdDictionary[language]
        ) {
          let selectedProducts = [];
          productIds.forEach((prodId) => {
            if (state.byIdDictionary[language][prodId]) {
              selectedProducts.push(state.byIdDictionary[language][prodId]);
            }
          });
          if (selectedProducts.length > 0) {
            newList = { data: selectedProducts };
          }
        }
      }

      state.listLoading = true;
      state.listError = null;
      state.list = newList;
      state.listFilters = filters;
      state.showFourOFour = false;
    },
    listProductsSuccess: (state, action) => {
      const { language, requestFilters, data } = action.payload;
      addProductsToDictionaries(data, language, state);

      // Update query dictionary
      if (!state.queryDictionary[language])
        state.queryDictionary[language] = {};
      state.queryDictionary[language][requestFilters] = action.payload;

      state.listLoading = false;
      state.list = action.payload;
      state.showFourOFour = false;
    },
    listProductsFail: (state, action) => {
      state.listLoading = false;
      state.list = action.payload;
      state.listError = action.payload?.message || true;
    },

    searchProducts: (state) => {
      state.listLoading = true;
      state.showFourOFour = false;
      state.searchError = false;
    },
    searchProductsSuccess: (state, action) => {
      const { language, requestFilters, data } = action.payload;
      addProductsToDictionaries(data, language, state);

      // Update query dictionary
      if (!state.queryDictionary[language])
        state.queryDictionary[language] = {};
      state.queryDictionary[language][requestFilters] = action.payload;

      const params = new URLSearchParams(requestFilters);
      state.currentSearchTerm = params.get("filter[name]");

      state.searchLoading = false;
      state.searchResults = action.payload;
      state.showFourOFour = false;
    },
    searchProductsFail: (state, action) => {
      state.searchLoading = false;
      state.search = action.payload;
      state.searchError = action.payload?.message || true;
    },

    showProduct: (state, action) => {
      const { language, slug } = action.payload;
      const productId = state.bySlugDictionary[language]?.[slug];
      state.show = productId ? state.byIdDictionary[language][productId] : null;
      state.showLoading = true;
      state.showError = null;
      state.errorRedirect = null;
      state.showFourOFour = false;
    },
    showProductAndLoadCMSBlocks: (state, action) => {
      const { language, slug } = action.payload;
      const productId = state.bySlugDictionary[language]?.[slug];
      state.show = productId ? state.byIdDictionary[language][productId] : null;
      state.showLoading = true;
      state.showError = null;
      state.errorRedirect = null;
      state.showFourOFour = false;
    },
    showProductSuccess: (state, action) => {
      const { language } = action.payload;

      // Add the product to the dictionaries if not already present
      addProductToDictionaries(action.payload, language, state);

      state.showLoading = false;
      state.show = action.payload;
      state.showFourOFour = false;
    },
    showProductFail: (state, action) => {
      const { responseStatus, errorRedirect } = action.payload || {};
      const showFourOFour = responseStatus === 404;

      state.showLoading = false;
      state.show = showFourOFour ? null : action.payload;
      state.showError = action.payload?.message || true;
      state.showFourOFour = showFourOFour;
      if (errorRedirect) state.errorRedirect = errorRedirect;
    },
  },
});

export const {
  listProducts,
  listProductsSuccess,
  listProductsFail,
  searchProducts,
  searchProductsSuccess,
  searchProductsFail,
  showProduct,
  showProductSuccess,
  showProductFail,
  showProductAndLoadCMSBlocks,
} = productsSlice.actions;
export const actions = productsSlice.actions;

export default productsSlice.reducer;
